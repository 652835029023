<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehicleCategoriesModal' hide-footer>
        <addVehicleCategories @closeAddVehicleCategories='toggleAddVehicleCategories()' @addVehicleCategoriesSuccess='addVehicleCategoriesSuccess()'></addVehicleCategories>
      </b-modal>
      <b-modal ref='editVehicleCategoriesModal' hide-footer>
        <editVehicleCategories
          :editingVehicleCategories='currentVehicleCategories'
          @closeEditVehicleCategories='toggleEditVehicleCategories()'
          @editVehicleCategoriesSuccess='editVehicleCategoriesSuccess()'
        ></editVehicleCategories>
      </b-modal>
      <div class='row'>
        <h2>VehicleCategories</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicleCategories()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleCategories from '../../components/addVehicleCategories';
import editVehicleCategories from '../../components/editVehicleCategories';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Category',
                field: 'Category',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Commercial',
                field: 'Commercial',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addVehicleCategoriesOpen: false,
      editVehicleCategoriesOpen: false,
      currentVehicleCategories: {}
    };
  },
  components: {
    addVehicleCategories,
    editVehicleCategories,
    Datatable,
  },
  created: function() {
    this.getVehicleCategories();
    this.addVehicleCategoriesOpen = false;
    this.editVehicleCategoriesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getVehicleCategories() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicleCategories', payload);
    },
    editItem(VehicleCategories) {
      this.toggleEditVehicleCategories();
      this.currentVehicleCategories = VehicleCategories;
    },
    toggleAddVehicleCategories() {
      if(this.addVehicleCategoriesOpen)
      {
        this.$refs.addVehicleCategoriesModal.hide()
      }
      else{
        this.$refs.addVehicleCategoriesModal.show()
      }
      this.addVehicleCategoriesOpen = !this.addVehicleCategoriesOpen;
    },
    addVehicleCategoriesSuccess() {
      this.toggleAddVehicleCategories();
      miniToastr['success']('VehicleCategories Added', 'Success', 1000, null);
      this.getVehicleCategories();
    },
    toggleEditVehicleCategories() {
      if(this.editVehicleCategoriesOpen)
      {
        this.$refs.editVehicleCategoriesModal.hide()
      }
      else{
        this.$refs.editVehicleCategoriesModal.show()
      }
      this.editVehicleCategoriesOpen = !this.editVehicleCategoriesOpen;
    },
    editVehicleCategoriesSuccess() {
      this.toggleEditVehicleCategories();
      miniToastr['success']('VehicleCategories Edited', 'Success', 1000, null);
      this.getVehicleCategories();
    }
  }
};
</script>
<style scoped>
.VehicleCategoriesThumbnail {
  height: 50px;
}
</style>
